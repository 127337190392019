import { ConsignmentState } from '@glow/entity-types'

export function getMapMarkerFromConsignmentState(consignmentState: ConsignmentState): MarkerVariant {
  if (consignmentState === 'DEVIATED') {
    return 'deviated'
  } else if (consignmentState === 'DELIVERED') {
    return 'delivered'
  } else if (consignmentState === 'RETURNED') {
    return 'returned'
  }
  return 'delivery'
}

export type MarkerVariant = 'pickup' | 'delivery' | 'delivered' | 'deviated' | 'returned'
