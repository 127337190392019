import { defaultPositionByCountry } from '@glow/molecule-components'
import { LatLngTuple } from 'leaflet'
import { useMemo } from 'react'
import { Shipment } from '../../types/coreEntitiesTypes'

export const useShipmentBounds = (shipment: Shipment) => {
  return useMemo(() => {
    const pickupLatLng = [shipment.get('deliveryLat'), shipment.get('deliveryLng')]
    const deliveryLatLng = [shipment.get('pickupLat'), shipment.get('pickupLng')]
    return (
      pickupLatLng.at(0) || deliveryLatLng.at(0) ? [pickupLatLng, deliveryLatLng] : [defaultPositionByCountry(), null]
    ) as LatLngTuple[]
  }, [shipment])
}
